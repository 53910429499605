import React from 'react'
import { Box } from '@theme-ui/components'
import Link from 'gatsby-link'
import generateLink from '../../helpers/generateLink'

export default function InternalLink({
  ctaClassName,
  variant,
  ctaName,
  ctaLink,
  isMultiLocationSite,
  city,
  style,
  sx,
  index
}) {
  const isEvenIndexButton = index ? index % 2 === 0 : true // if index has been set there we have added functionaility for multiple buttons and need changing styling for buttons
  return (
    <Link className={`gatsby-link`} to={generateLink(ctaLink, isMultiLocationSite, city)} style={style}>
      {console.log(`${variant}.${isEvenIndexButton ? 'ctaButton' : 'secondaryCtaButton'}`)}
      <Box
        as="span"
        variant={`${variant}.${isEvenIndexButton ? 'ctaButton' : 'secondaryCtaButton'}`}
        className={`${ctaClassName ? ctaClassName : ''} ${isEvenIndexButton ? 'ctaButton' : 'secondaryCtaButton'} `}
        sx={{ display: 'flex', ...sx }}
      >
        {ctaName}
      </Box>
    </Link>
  )
}
