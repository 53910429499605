import React from 'react'
import { Link, Box } from '@theme-ui/components'
import generateLink from '../../helpers/generateLink'

export default function ExternalLink({
  ctaClassName,
  variant,
  ctaName,
  ctaLink,
  isMultiLocationSite,
  city,
  style,
  sx,
  index
}) {
  const isEvenIndexButton = index ? index % 2 === 0 : true
  return (
    <Link href={generateLink(ctaLink, isMultiLocationSite, city)} target={'_blank'} rel="noreferrer" style={style}>
      <Box
        as="span"
        variant={`${variant}.${isEvenIndexButton ? 'ctaButton' : 'secondaryCtaButton'}`}
        className={`${ctaClassName ? ctaClassName : ''} ${isEvenIndexButton ? 'ctaButton' : 'secondaryCtaButton'} `}
        sx={{ display: 'flex', ...sx }}
      >
        {ctaName}
      </Box>
    </Link>
  )
}
